    <!-- Start Page content -->
    <div class="content">
        <div class="container-fluid">

            <div class="row">
                <div class="col-12">
                    <div class="card-box">
                        <div class="topHeader">
                            <h3>Settings
                            </h3>
                        </div>
                        <div class="table-rep-plugin" >
                            <div class="loading" *ngIf="loading">
                                <i class="fa fa-circle-o-notch fa-spin"></i>
                            </div>
                            <div class="table-responsive" data-pattern="priority-columns" >
                                <form>
                                    <ul class="sett">
                                        
                                        <li>
                                            <div class="checkbox checkbox-primary">
                                                <input type="checkbox" id="checkbox1"  name="chek1" data-md-icheck  [checked]="setting.pending == 1" (change)="selectItem($event.target.checked, 'pending')">
                                                <label for="checkbox1">
                                                    قيد الانتظار 
                                                </label>
                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input type="checkbox" id="checkbox2"  name="chek2" data-md-icheck  [checked]="setting.on_progress == 1" (change)="selectItem($event.target.checked,'on_progress')">
                                                <label for="checkbox2">
                                                    قيد التنفيذ
                                                </label>
                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input   type="checkbox" id="checkbox3" name="chek3" data-md-icheck  [checked]="setting.delivered_paid == 1" (change)="selectItem($event.target.checked,'delivered_paid')">
                                                <label for="checkbox3">
                                                    تم الحساب
                                                </label>
                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input type="checkbox" id="checkbox4"   name="chek4" data-md-icheck  [checked]="setting.returned == 1" (change)="selectItem($event.target.checked,'returned')">
                                                <label for="checkbox4">
                                                    راجع
                                                </label>
                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input type="checkbox" id="checkbox5"  name="chek5" data-md-icheck [checked]="setting.returned_client == 1" (change)="selectItem($event.target.checked,'returned_client')">
                                                <label for="checkbox5">
                                                    راجع مسلم للعميل 
                                                </label>
                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input   type="checkbox" id="checkbox6" name="chek6" data-md-icheck  [checked]="setting.delivered == 1" (change)="selectItem($event.target.checked,'delivered')">
                                                <label for="checkbox6">
                                                    تم التوصيل
                                                </label>
                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input   type="checkbox" id="checkbox7" name="chek7" data-md-icheck  [checked]="setting.balance == 1" (change)="selectItem($event.target.checked,'balance')">
                                                <label for="checkbox7">
                                                   رصيد
                                                </label>
                                            </div>
                                            
                                        </li>
                                    </ul>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="userName">About Us<span class="text-danger">*</span></label>
                                            <!-- <textarea rows="5" type="text" required class="form-control"  name="about_us" ngModel [(ngModel)]="setting.about_us"></textarea> -->
                                            <textarea id="demo2"  name="about_us"  [(ngModel)]="setting.about_us"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="userName">Countact Us<span class="text-danger">*</span></label>
                                            <!-- <textarea rows="5" type="text" required class="form-control"  name="contact_us" ngModel [(ngModel)]="setting.contact_us"></textarea> -->
                                            <textarea id="demo1"  name="contact_us"  [(ngModel)]="setting.contact_us"></textarea>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-info waves-effect waves-light" (click)="SaveSetting()">Save</button>
                                </form>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <!-- end row -->

        </div> <!-- container -->

    </div> <!-- content -->


